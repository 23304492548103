// Variable overrides
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin word-wrap() {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

$blue: #61aeff;
$white: #ffffff;
$dark: #333333;

$positive: #09a148;
$negative: #ff5722;
