// Here you can add other styles
@import "../scss/_variables.scss";

//@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese);
//
//* {
//  font-family: "Open Sans";
//  //font-size: 4em;
//}

body {
  background-color: #262626;
  //overflow: visible !important;
}

@media (min-width: 992px) {
  .header-fixed .app-body {
    margin-top: 74px;
  }
}

.sidebar {
  color: #ffffff;
  &-dark {
    background-color: #212121;
  }
  &-category {
    font-weight: bold;
    font-size: 1.3rem;
    padding: .75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: .8rem;
    }
    .badge {
      background-color: transparent;
      border-radius: 2px;
      color: red;
      border-color: red;
    }
  }
  .nav-dropdown {
    &-title {
      font-weight: bold;
    }
    &.open {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -250px;
}

@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    position: fixed;
    z-index: 1019;
    width: 280px;
    height: 100vh;
  }
}

.app-body .sidebar {
  flex: 0 0 250px;
  order: -1;
}

.sidebar {
  width: 250px;
}

.main {
  background-color: #262626;
  padding: 3rem;
  .container-fluid {
    &:first-of-type {
      padding: 0;
    }
  }
}

@media (min-width: 992px) {
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main {
    margin-left: 280px;
  }
}

.nav-item {
  .nav-link {
    color: #656565;
    &.active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.period {
  margin-right: .6rem;
  color: #53555c;
}

.page-header {
  padding: 15px;
  height: 65px;
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
}

.positive-number {
  color: #09a148 !important;
}

.negative-number {
  color: #ff5722 !important;
}

.default-number {
  color: #949495 !important;
}

.table {
  @media screen and (min-width: 1251px) {
    .thead-light th {
      background-color: #f0f3f5;
    }
  }
}

@media screen and (max-width: 1251px) {
  table caption {
    //font-size: 1.3em;
  }

  table thead {
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
  table tr {
    border: 1px solid #ddd !important;
    display: block;
    margin-bottom: 1rem;
    &:first-child {
      display: none;
    }
    &:hover {
      background-color: #ffffff !important;
    }
  }
  table td {
    //display: block;
    font-size: 0.9rem;
    text-align: right;
    vertical-align: middle;
    border-top: 1px solid #dddddd !important;
    display: flex;
    justify-content: space-between;
    &:hover {
      background-color: #f9f9f9;
    }
    &:first-child {
      border-top: none !important;
    }
  }
  table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    font-size: 0.8rem;
  }
  table td:last-child {
    //border-bottom: 0;
  }
}

.logo-text {
  //font-weight: bold;
  font-size: 1.6rem;
  font-family: Serif, serif;
}

.nav-logo-container {
  width: 200px;
  display: flex;
  justify-content: center;
  position: relative;
  left: -20px;
}

.background-gray {
  background-color: #f6f3f5;
  border-color: #f6f3f5;
  .fa-chevron-down {
    color: $blue;
    font-size: .6rem;
    margin-left: .5rem;
  }
}

.background-light-blue {
  background-color: #dae7fb;
  border-color: #dae7fb;
  font-weight: bold;
  color: $blue;
  min-width: 134.91px;
  justify-content: center;
  text-align: center;
}

.fa {
  color: $blue;
}

.blue {
  color: $blue !important;
}

.white {
  color: #ffffff !important;
}

.navbar-error-bar {
  background-color: red;
}

@media (min-width: 992px) {
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 280px;
  }
}

.inverted-badge {
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  margin-left: .5rem;
  position: relative;
  left: -5px;
}

.inverted-badge-positive {
  border: 1px solid $positive;
  color: $positive;
  //color: #09a148 !important;
  padding: 1px 6px;
}

.inverted-badge-negative {
  border: 1px solid $negative;
  color: $negative;
  padding: 1px 6px;
}

.inverted-badge-default {
  border: 1px solid #949495;
  color:  #949495;
  padding: 1px 6px;
}

.bg-primary, .card.bg-primary {
  background-color: $blue !important;
}

.letter-icon {
  background-color: #ffffff;
  color: $dark;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-transform: uppercase;
  @include flex-center;
}


.page-view {
  &-info {
    color: $white;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &-university {
    font-family: 'Garamond', serif;
    font-weight: bold;
    font-size: 1.2rem;
    i {
      font-size: 1.3rem;
    }
  }
  &-subheader {
    color: #f93f5e;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
  }
  &-title {
    font-weight: bold;
    font-size: 1.7rem;
    max-width: 650px;
    margin: 0 auto;
  }
  &-subject {
    font-weight: bold;
    font-size: 1.7rem;
  }
  &-views {
    margin-top: .3rem;
  }
}


.input-error {
  border: 1px solid #e00000 !important;
}

.error-text {
  color: #e00000;
}
//
//#simple-menu {
//  display: none;
//}



.MuiSnackbarContent-root {
  background-color: #e00000 !important;
}

.usage-report {
  position: relative;
}

.back-to-top-btn {
  position: fixed !important;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
  background-color: #61aeff !important;
  .MuiFab-root:focus, button:focus {
    outline: none !important;
  }
  i {
    font-size: 1.2rem;
    //margin-left: .7rem;
    //padding: .2rem .25rem;
    //background-color: #a8c1e2;
    color: #ffffff;
  }
  &-container {
    position: fixed;
    right: -100px;
    bottom: 2rem;
    transition: right 0.5s;
    cursor: pointer;
    //background-color: white;
    font-size: 20px;
    padding: 10px;
    button:focus {
      outline: none;
    }
  }
  &-transition {
    right: 20px;
  }
}

.pc-r {
  padding-right: 10px;
  display: flex;
}

.pc-l {
  padding-left: 10px;
  display: flex;
}

.default-sort-btn {
  position: relative;
  top: -1px;
  font-size: 0.6rem;
  margin-left: .7rem;
  padding: .2rem .25rem;
  background-color: rgba(97, 174, 255, 0.14);
  cursor: pointer;
}

.table {
  margin-bottom: .2rem;
  thead tr {
    //border-bottom: 2px dashed #c8ced3;
    background-image: linear-gradient(to left, #d2d2d2 70%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size:  11px 2px;
    background-repeat: repeat-x;
  }
  thead th {
    border-bottom: none;
    //min-width: 110px;
  }
  th {
    color: #979797;
    font-weight: 700;
    border-top: none;
    padding: .75rem .75rem .5rem;
  }
  td {
    border-top: none;
    font-weight: 400;
  }
  &-videos {
    thead th {
      &:first-of-type {
        min-width: auto;
      }
      min-width: 150px;
    }
  }
}


.alt-panel {
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  //min-height: 425px;
  flex: 1 1;
  .panel-header {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: .5rem;
    margin-right: .5rem;
    display: flex;
    align-items: center;
  }
  .panel-title {
    margin-left: 0;
  }
  .panel-body {
    padding-top: .75rem;
    min-height: 280px;
  }
  .form-group {
    .settings-label {
      font-size: 1rem;
      font-weight: 500;
    }
    .settings-panel-input {
      max-width: 300px;
    }
  }
  &-full {
    min-height: 1940px;
  }
}

.relative {
  position: relative;
}